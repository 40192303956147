<template>
  <div id="incrementality_container" class="chart-tab">
    <span v-if="isSingleIOSelected && columns.length" class="pan-head-icon-wrap">
      <span class="export-intip4 export-intip6" data-tool-tip="Export" @click="showOptions = true">
        <font-awesome-icon :icon="['far', 'ellipsis-h']" class="pan-head-icon" />
      </span>
      <div v-if="showOptions" class="download-options-modal">
        <div class="modal-header">
          <button class="close-btn" @click="showOptions = false">X</button>
        </div>
        <div class="options-content">
          <p>Download as:</p>
          <button class="option-btn" @click="handleDownload('pdf', 'incrementality_container')">
            PDF
          </button>
          <button class="option-btn" @click="handleDownload('csv')">CSV</button>
        </div>
      </div>
    </span>
    <div class="chart-filters">
      <div style="display: flex;">
        <b-select
          v-if="isSingleIOSelected && columns.length"
          :options="groupByOptions"
          :selected="groupByValue"
          :class-name="'greyBackground'"
          @change="onChangeGroupBy"
        />
        <div
          v-if="!loading"
          class="select-campaign-btn"
          :class="[isSingleIOSelected && columns.length ? 'formatSelectBtn' : '']"
        >
          <k-button :size="3" label="SELECT CAMPAIGN" @click="handleSelectCampaign" />
        </div>
      </div>
      <div v-if="groupByValue !== 'overall'" class="inc-tooltip-container">
        <div v-if="isSingleIOSelected && columns.length" class="incrementality-tooltip">
          <!-- <font-awesome-icon :icon="['fas', 'info-circle']" class="cp-tooltip" /> -->
        </div>
        <div v-if="isSingleIOSelected && columns.length" class="incrementality-tooltip-cont">
          <!--<incrementality-tooltip /> -->
        </div>
      </div>
    </div>
    <span
      v-if="!loading && columns.length && groupByValue !== 'overall'"
      class="opt-txt holdsort sort-btn"
    >
      Sort:
      <span class="intip4 intip6" style="margin-left: 3px;" data-tool-tip="Sort: high/low">
        <font-awesome-icon :icon="['fas', 'sort']" class="cp-sort" @click="sortChart()" />
      </span>
    </span>
    <div v-if="isSingleIOSelected">
      <flip-horizontal-bar-chart
        v-if="!loading && columns.length"
        class="bar-chart container-chart"
        :columns="columns"
        :tooltip="tooltip"
        :x-axis-labels-multiline="groupByValue !== 'overall'"
        :x-axis-label-format="`0.[00]%`"
        :chart-height="groupByValue === 'overall' ? 260 : 400"
        :bar-width="groupByValue === 'overall' ? 50 : 0.7"
        :is-bar-width-ratio="groupByValue !== 'overall'"
      />
      <div
        v-if="!loading && columns.length && groupByValue === 'overall'"
        class="inc-stats-container"
      >
        <div class="inc-stat-wrap">
          <div class="stat-col left-col">
            <div class="inc-stat-row">
              <div
                class="stat-row-name intip7"
                data-tool-tip="The number of unique households in the exposed group; should equal reach across the ad groups where incrementality is applied in the Flip dashboard."
              >
                Exposed count
              </div>
              <div class="stat-row-val">{{ formatVal(overall.exposedCount, '0,0') }}</div>
            </div>
            <div class="inc-stat-row">
              <div
                class="stat-row-name intip7"
                data-tool-tip="The number of conversions recorded against the exposed group. Should equal [event] number in upper right across the ad groups where incrementality is applied in the Flip dashboard."
              >
                Exposed conversions
              </div>
              <div class="stat-row-val">{{ formatVal(overall.exposedConversions, '0,0') }}</div>
            </div>
            <div class="inc-stat-row">
              <div
                class="stat-row-name intip7"
                data-tool-tip="Exposed Conversions/Count — the rate at which unique households are converting"
              >
                Exposed conv. rate
              </div>
              <div class="stat-row-val">{{ formatRateAsPercentage(overall.exposedRate) }}</div>
            </div>
          </div>
          <div class="stat-col mid-col">
            <div class="inc-stat-row con">
              <div
                class="stat-row-name intip7"
                data-tool-tip="The number of unique households in the control group."
              >
                Control count
              </div>
              <div class="stat-row-val">{{ formatVal(overall.controlCount, '0,0') }}</div>
            </div>
            <div class="inc-stat-row con">
              <div
                class="stat-row-name intip7"
                data-tool-tip="The number of conversions recorded against the control group."
              >
                Control conversions
              </div>
              <div class="stat-row-val">{{ formatVal(overall.controlConversions, '0,0') }}</div>
            </div>
            <div class="inc-stat-row con">
              <div
                class="stat-row-name intip7"
                data-tool-tip="Control Conversions/Count — the rate at which unique households are converting"
              >
                Control conv. rate
              </div>
              <div class="stat-row-val">{{ formatRateAsPercentage(overall.controlRate) }}</div>
            </div>
          </div>
          <div class="stat-col right-col">
            <div class="inc-stat-row ls-tip">
              <div
                class="stat-row-name up-layer intip7 "
                data-tool-tip="What percent more likely someone who is exposed to this campaign is to convert than someone who is not exposed to this campaign."
              >
                Incremental Lift
              </div>
              <div class="stat-row-val">
                {{ formatPercentage(overall.liftPercentage, overall.incrementalityInfinity) }}
              </div>
            </div>
            <div class="inc-stat-row">
              <div
                class="stat-row-name intip7"
                data-tool-tip="If checked, incrementality results can not be the result of random chance."
              >
                Statistical Significance
              </div>
              <div v-if="overall.significant === 'NA'" class="stat-row-val">NA</div>
              <div v-else class="stat-row-val stat-check">
                <font-awesome-icon
                  :icon="['far', overall.significant === '✓' ? 'check' : 'times']"
                  :class="[overall.significant === '✓' ? 'positiveColor' : 'negativeColor']"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading && !columns.length" class="txt-center">
        No data is available to show
      </div>
    </div>
    <b-loading-spinner v-if="loading" class="txt-center" />
    <div v-if="!isSingleIOSelected && !loading">
      <div class="txt-center">
        Choose only one campaign to see Incrementality data
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import { isBlank } from 'adready-api/helpers/common';
import numeral from 'numeral';
import jStat from 'jstat/dist/jstat';
import { pageExportToPDF, formatNumberAsLargeNumber } from '@/util/utility-functions';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import * as CONSTANT from '~/constant';
import * as util from '~/util/utility-functions';

import incrementalityDataMixin from '~/components/mixins/incrementality-data-mixin';

export default {
  name: 'Incrementality',
  components: {
    FlipHorizontalBarChart: () =>
      import(
        /* webpackChunkName: "horizontal-bar-chart" */ '@/components/charts/flip-horizontal-bar-chart.vue'
      ),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
  },

  mixins: [incrementalityDataMixin],

  data() {
    return {
      showOptions: false,
      CONSTANT,
      groupByValue: CONSTANT.INCREMENTALITY_GROUP_BY_OPTIONS[0].id,
      columns: [],
      tooltip: [],
      overall: {},
      resBkp: [],
      sortOrder: 'desc',
      pageExportToPDF,
    };
  },

  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    selectedIOs: get('dashboard/GET_SELECTED_IOS'),
    ioOptions: get('dashboard/filters@ioOptions'),
    showFilters: get('common/showFilters'),

    groupByOptions() {
      return CONSTANT.INCREMENTALITY_GROUP_BY_OPTIONS;
    },
    isSingleIOSelected() {
      return this.selectedIOs.length === 1;
    },
  },
  watch: {
    async selectedIOs() {
      try {
        await this.loadGraphData(this.payload);
      } catch (err) {
        console.error('error mounting incrementality chart', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
  },
  async mounted() {
    try {
      await this.loadGraphData(this.payload);
    } catch (err) {
      console.error('error mounting incrementality chart', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    handleDownload(format, elementId = '') {
      this.showOptions = false;
      if (format === 'csv') {
        this.download();
      } else {
        this.pageExportToPDF(elementId);
      }
    },
    download() {
      if (!this.loading && !this.columns.length) {
        return;
      }

      try {
        const header = [
          'Name',
          'Exposed count',
          'Exposed conversions',
          'Exposed conv. rate',
          'Control count',
          'Control conversions',
          'Control conv. rate',
          'Incremental Lift',
          'Statistical Significance',
        ];
        const lines = [];
        const storeData = (obj) => {
          const data = [];
          const {
            name,
            exposedCount,
            exposedConversions,
            exposedRate,
            controlCount,
            controlConversions,
            controlRate,
            liftPercentage,
            significant,
          } = obj;
          const updatedSignificant = significant === '✓' ? 'Yes' : 'No';
          data.push([
            name,
            exposedCount,
            exposedConversions,
            exposedRate,
            controlCount,
            controlConversions,
            controlRate,
            liftPercentage,
            updatedSignificant,
          ]);
          return data;
        };

        if (this.groupByValue === 'overall') {
          lines.push(...storeData(this.overall));
        } else {
          this.exportRaw.forEach((row) => {
            lines.push(...storeData(row));
          });
        }

        const csvData = [header, ...lines];
        const fileName = `export_${this.groupByValue}.csv`;
        util.downloadCSVFile(csvData, fileName.replace(/ /g, '_').toLowerCase());
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      }
    },
    sortChart() {
      if (this.sortOrder === 'asc' || !this.sortOrder) {
        this.sortOrder = 'desc';
      } else {
        this.sortOrder = 'asc';
      }
      return this.processResults(this.resBkp);
    },
    handleSelectCampaign() {
      this.$store.dispatch('common/setSelectOneCampaignType', CONSTANT.WINDOW_TYPE_INCREMENTALITY);
      if (!this.showFilters) {
        this.$store.dispatch('common/setShowFilters', true);
        setTimeout(() => {
          if (document.getElementById('ioOptionDropdown')) {
            document.getElementById('ioOptionDropdown').click();
          }
        }, 400);
      }
    },
    formatVal(value, format) {
      return formatNumberAsLargeNumber(value, format);
    },
    formatPercentage(value, isInfinity) {
      return isInfinity ? 'NA' : this.formatVal(value, '0%');
    },
    formatRateAsPercentage(value) {
      return numeral(value).format('0.[00]%');
    },
    calculateIncrementalityMetrics(obj) {
      const { exposedRate, exposedCount, controlRate, controlCount } = obj;

      if (exposedRate === 0 || controlRate === 0) {
        obj.significant = 'NA';
      } else {
        const pValue = jStat.fn
          .twoSidedDifferenceOfProportions(exposedRate, exposedCount, controlRate, controlCount)
          .toFixed(6);
        obj.significant = pValue < 0.05 ? '✓' : '𐄂';
      }
    },
    async onChangeGroupBy(val) {
      const payload = { ...this.payload };
      this.groupByValue = val;

      try {
        await this.loadGraphData(payload);
      } catch (err) {
        console.error('Error in updating incrementality chart ->', err.message, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    buildTooltipDataRow(rowCss, title, titleCss, displayValue, valueCss) {
      return {
        rowCss,
        columns: [
          {
            value: title,
            css: titleCss,
          },
          {
            value: displayValue,
            css: valueCss,
          },
        ],
      };
    },
    buildTooltipEmptyRow() {
      return {
        rowCss: 'ctip-div',
      };
    },
    async processResults(res) {
      const yEntries = ['x'];
      const exposedBar = ['Exposed Conversion Rate'];
      const controlBar = ['Control Conversion Rate'];
      const data = [];
      this.tooltip = [];
      this.exportRaw = [];

      res.forEach((row) => {
        this.calculateIncrementalityMetrics(row);
      });

      if (this.sortOrder) {
        res = res.sort((a, b) => {
          if (this.sortOrder === 'asc') {
            return a.liftPercentage - b.liftPercentage;
          }
          return b.liftPercentage - a.liftPercentage;
        });
      }

      res.forEach((row) => {
        if (this.groupByValue === 'overall') {
          this.overall = { ...row };
        }

        this.exportRaw.push({ ...row });
        yEntries.push(row.name);
        exposedBar.push(row.exposedRate * 100);
        controlBar.push(row.controlRate * 100);

        const tooltipRows = [];
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap exp',
            'Exposed count',
            'ctip-row-name',
            this.formatVal(row.exposedCount, '0,0'),
            'ctip-row-val'
          )
        );
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap exp',
            'Exposed conversions',
            'ctip-row-name',
            this.formatVal(row.exposedConversions, '0,0'),
            'ctip-row-val'
          )
        );
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap exp',
            'Exposed conv. rate',
            'ctip-row-name',
            this.formatRateAsPercentage(row.exposedRate),
            'ctip-row-val'
          )
        );
        tooltipRows.push(this.buildTooltipEmptyRow());

        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap con',
            'Control count',
            'ctip-row-name',
            this.formatVal(row.controlCount, '0,0'),
            'ctip-row-val'
          )
        );
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap con',
            'Control conversions',
            'ctip-row-name',
            this.formatVal(row.controlConversions, '0,0'),
            'ctip-row-val'
          )
        );
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-row-wrap con',
            'Control conv. rate',
            'ctip-row-name',
            this.formatRateAsPercentage(row.controlRate),
            'ctip-row-val'
          )
        );
        tooltipRows.push(this.buildTooltipEmptyRow());

        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-head-wrap',
            'Incremental Lift',
            'ctip-name',
            this.formatPercentage(row.liftPercentage, row.liftPercentageInfinity),
            'ctip-dif'
          )
        );
        let cssSignificant = 'ctip-dif';
        if (row.significant !== 'NA') {
          cssSignificant = row.significant === '✓' ? 'ctip-positive' : 'ctip-negative';
        }
        tooltipRows.push(
          this.buildTooltipDataRow(
            'ctip-head-wrap',
            'Statistical Significance',
            'ctip-name',
            row.significant,
            cssSignificant
          )
        );

        this.tooltip.push(tooltipRows);
      });

      data.push(yEntries);
      data.push(exposedBar);
      data.push(yEntries);
      data.push(controlBar);

      this.columns = data;
    },
    validateParams(payload, advertiser) {
      const advertiserId = advertiser ? advertiser.id : null;
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = ['client', 'advertiser', 'xandrId', 'pixel', 'category', 'startDate', 'endDate'];
      return !util.isAnyBlank(payload, keys);
    },
    async loadGraphData(payload) {
      this.columns = [];
      this.loading = true;
      payload.groupBy = this.groupByValue;

      // call incrementality api and get res
      try {
        const dataValidated = this.validateParams(payload, this.advertiser);
        let res = null;
        if (dataValidated) {
          res = await advertiserReportsAPI.incrementalityDistribution(
            this.advertiser.id,
            buildQueryString(payload)
          );
        }
        this.resBkp = res;
        this.sortOrder = 'desc';
        if (res && res.length) {
          this.processResults(res);
        }
      } catch (err) {
        console.error('error in fetching incrementality data ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-campaign-btn.formatSelectBtn {
  width: 200px;
  justify-content: space-between;
  margin-left: 12px;
}
::v-deep .select-campaign-btn .k-btn:focus {
  background-color: var(--primarycolor);
}
::v-deep .inc-canvas {
  height: 400px;
  overflow: auto;
  display: flex;
}
@media screen {
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .opt-btn:hover {
    color: var(--primarycolor);
  }
}

::v-deep .dropdown {
  width: 220px !important;
}

::v-deep .c3-event-rect {
  cursor: auto !important;
}
.pan-by {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  color: #cad1d6;
}
.loading-spinner {
  width: 100px;
  height: 100px;
}
.container-chart {
  position: relative;
  z-index: 2;
  margin-top: 40px;
  margin-bottom: -30px;
  border: 0px #ffffff none;
}
.chart-tab {
  .txt-center {
    text-align: center;
    margin-top: 100px;
  }
}
.sort-btn {
  margin-top: 12px;
  display: inline-block;
  margin-left: 102px;
  position: relative;
  top: 35px;
}
.chart-filters {
  display: flex;
  position: relative;
  justify-content: space-between;
  .incrementality-tooltip {
    bottom: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    cursor: pointer;

    .cp-tooltip {
      position: relative;
      top: 12px;
      left: 12px;
      width: 15px;
      height: 20px;
    }
    &:hover ~ .incrementality-tooltip-cont {
      transform: scale(1);
      transition: ease 0.4s;
    }
  }
  .incrementality-tooltip-cont {
    position: absolute;
    right: 0;
    top: 56px;
    width: 768px;
    background: #fff;
    z-index: 10;
    transition: ease 0.4s;
    transform: scale(0);
    border-radius: 4px;
    & .tooltip-cont {
      position: relative;
    }
  }
  .chart-widget {
    position: absolute;
    right: 55px;
  }
  .flp-opt-panel {
    top: 40px;
    right: 30px;
  }
}

.opt-txt {
  font-size: 12px;
  margin-top: 10px;
}
.intip4 {
  position: relative;
  top: 0px;
  display: inline-block;
}
.intip4[data-tool-tip]::after {
  content: attr(data-tool-tip);
  display: inline-block;
  position: absolute;
  width: 100px;
  font-size: 12px;
  line-height: 1.2em;
  background-color: #35363c;
  color: #5b5b5b;
  padding: 10px 12px;
  border-radius: 3px;
  left: 0;
  margin-left: -65px;
  bottom: 0%;
  transform: scale(0);
  transition: ease 0.3s;
  transform-origin: bottom center;
  z-index: 99;
  text-align: center;
  pointer-events: none;
}
.intip4[data-tool-tip]::after {
  background-color: rgba(245, 245, 250, 0.9);
  color: #222;
  width: 140px;
  margin-left: -75px;
  font-size: 12px;
  pointer-events: none;
}
.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: -145%;
}
.intip6[data-tool-tip]::after {
  background-color: rgba(245, 245, 250, 0.9);
  color: #222;
  width: 70px;
  bottom: 100%;
  margin-left: -35px !important;
  font-size: 12px;
  font-weight: 600;
  padding: 6px;
  text-align: center;
  pointer-events: none;
}
.intip6[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 145%;
}
.cp-sort {
  cursor: pointer;
  color: #69737d;
  font-size: 12px;
}
.cp-sort:hover {
  color: var(--primarycolor);
}

.inc-stats-container {
  margin-top: 60px;
  padding-top: 40px;
  box-shadow: 0px -41px 40px -41px rgb(137 206 239 / 20%);
}
.inc-stat-wrap {
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.stat-col {
  display: inline-block;
  width: 29%;
}
.stat-row-name {
  display: inline-block;
}
.stat-row-val {
  display: inline-block;
  float: right;
}
.stat-col.left-col {
  color: var(--primarycolor);
}
.stat-col.mid-col {
  margin: 0px 3%;
  margin-right: 0px;
  padding: 0px 3%;
  border-left: 1px solid rgba(200, 220, 250, 0.1);
  /* border-right: 1px solid rgba(200, 220, 250, 0.1); */
  color: #abb2c5;
  width: 32%;
}
.stat-col.right-col {
  color: #fff;
  vertical-align: top;
  background-color: #383a44;
  padding: 10px 3% 10px 3%;
  width: 32%;
}

.positiveColor {
  color: var(--primarygreen);
}

.negativeColor {
  color: var(--primaryred);
}

// gap between bars
::v-deep g.c3-chart-bar.c3-target.c3-target-Control-Conversion-Rate {
  transform: translateY(4px);
}

// bar glow
// ::v-deep path.c3-shape.c3-shape-0.c3-bar.c3-bar-0 {
//   // filter: drop-shadow(4px 0px 14px rgba(0, 171, 183, 0.2));
// }

::v-deep .chart-filters .dropdown .dropdown-menu li {
  font-size: 14px;
}
.pan-head-icon-wrap {
  position: absolute;
  right: 17px;
  top: 11px;
}
.pan-head-icon {
  color: #97a7bb;
}
.pan-head-icon:hover {
  color: var(--primarycolor) !important;
}
.export-intip4 {
  position: relative;
  top: 0px;
  display: inline-block;
}
.light-theme {
  .export-intip4::after {
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
  }
  .export-intip6[data-tool-tip]::after {
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
    font-weight: 400 !important;
  }
}
.export-intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: 99;
  display: inline-block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -65px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #35363c;
  border-radius: 3px;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
  -webkit-transform: scale(0);
  transform: scale(0);
  transform-origin: bottom center;
}
.export-intip4[data-tool-tip]::after {
  width: 140px;
  margin-left: -75px;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.export-intip6[data-tool-tip]::after {
  background-color: rgba(245, 245, 250, 0.9);
  color: #222;
  width: 70px;
  bottom: 100%;
  margin-left: -35px !important;
  font-size: 12px;
  font-weight: 600;
  padding: 6px;
  text-align: center;
  pointer-events: none;
}
.export-intip6[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 145%;
}
.intip7 {
  position: relative;
  top: 0px;
  display: inline-block;
}
.intip7[data-tool-tip]::after {
  content: attr(data-tool-tip);
  display: inline-block;
  position: absolute;
  width: 290px;
  font-size: 12px;
  line-height: 1.3em;
  background-color: #dfdfe4;
  color: #222;
  padding: 12px 15px;
  border-radius: 4px;
  left: 0;
  margin-left: -65px;
  bottom: 100%;
  transform: scale(0);
  transition: ease 0.3s;
  z-index: 99;
  text-align: left;
  pointer-events: none;
}
.intip7[data-tool-tip]::after {
  background-color: #dfdfe4;
  color: #222;
  width: 290px;
  font-size: 12px;
  pointer-events: none;
  margin-left: -66px !important;
}
.intip7[data-tool-tip]:hover::after {
  transform: scale(1);
}
::v-deep .up-layer.intip7[data-tool-tip]:hover::after {
  bottom: 29px !important;
}

.download-options-modal {
  position: absolute;
  top: 200%;
  left: -970%;
  transform: translate(-20%, -30%);
  background-color: #fff;
  padding: 8px 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  text-align: justify;
  width: 160px;
}

.options-content p {
  padding: 4px;
  font-size: 14px;
  color: #333;
}

.options-content button {
  margin: 5px;
  padding: 8px 15px;
  font-size: 14px;
  background-color: #00abb7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.options-content button:hover {
  background-color: #007b8f;
}
.option-btn {
  padding: 10px;
}
.close-btn {
  background: white;
}

.modal-header {
  text-align: right;
}
</style>
